import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Logo from "../components/logo"
import SEO from "../components/seo"
import "./index.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div>
      <div style={{ marginBottom: 30 }}>
        <Logo width={512} />
      </div>
      <div className={"mc_embed_signup"}>
        <form
          action="https://safeguardpass.us2.list-manage.com/subscribe/post?u=0605a1c64c2b4169f6f9615d0&amp;id=4738058738"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
        >
          <div className={"mc_embed_signup_form_container"}>
            <input
              type="email"
              name="EMAIL"
              className="email"
              id="mce-EMAIL"
              placeholder="Email Address"
              required
            />
            <input
              type="submit"
              value="Subscribe"
              name="subscribe"
              className="button"
            />
          </div>
        </form>
      </div>
      <div className={"privacy"}>
        <Link to="/privacy">Privacy</Link>
        {" | "}
        <Link to="/terms">Terms of Use</Link>
      </div>
    </div>
  </Layout>
)

export default IndexPage
